import React, { Component } from 'react';
import styled from 'styled-components';

class StyledHeadline extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {  
    return (
      <Container>
        <Text1>{this.props.content}</Text1>
        <Text2>{this.props.content}</Text2>
        <Text3>{this.props.content}</Text3>
      </Container>
    )
  }
}

const Container = styled.div`
  letter-spacing: 0.2ex;
  display: inline-grid;
`
const Text1 = styled.span`
  color: #00B1EB;
  grid-column: 1;
  grid-row: 1;
  transform: rotate(1deg);  
  z-index: 1;
`
const Text2 = styled.span`
  color: #FFED00;
  transform: rotate(-1deg);
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
`
const Text3 = styled.span`
  color: #E30413;
  grid-column: 1;
  grid-row: 1;
  z-index: 3;
`


export default StyledHeadline;
