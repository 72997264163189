import React, { Component } from 'react';

import axios from 'axios';
import { apiUrl } from '../helpers'
import { t, capitalize } from '../helpers'

import styled from 'styled-components'

class QuizPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_entries: [],
      others_entries: [],
      questionCounter: 0,
      maxQuestions: 5,
      score: 0,
      othersIndex: -1,
      userIndex: -1,
      status: "loading"
    }
  }

  setupNextRound() {
    
    if(this.state.questionCounter >= this.state.maxQuestions) {
      this.setState({status: "end"})
      return;
    }

    console.log("setupNextRound", this.state.userIndex, this.state.othersIndex, this.state.user_entries, this.state.others_entries)

    // roll dice to see if we should show a user entry
    let showUserEntry = false;
    if(this.state.userIndex < this.state.user_entries.length - 1) {
      if(Math.random() < 0.5) {
        showUserEntry = true;
      }
    }
    console.log("showUserEntry", showUserEntry)

    const newState = {
      showUserEntry,
      status: "ready",
      questionCounter: this.state.questionCounter + 1
    }

    if(showUserEntry) {
      this.setState({
        ...newState,
        currentEntry: this.state.user_entries[this.state.userIndex + 1],
        userIndex: this.state.userIndex + 1,
      })
    } else {
      if(this.state.othersIndex < this.state.others_entries.length - 1) {
        this.setState({
          ...newState,
          currentEntry: this.state.others_entries[this.state.othersIndex + 1],
          othersIndex: this.state.othersIndex + 1,
        })
      } else {
        console.log("no more entries to show")
        this.setState({status: "end"})
        return;
      }
    }

  }

  answer(a) {
    if(a == true && this.state.showUserEntry) {
      this.setState({roundResult: t(this.props.translations, "quiz_correct", this.props.locale) + t(this.props.translations, "quiz_had", this.props.locale), status: "answer", score: this.state.score+1})
    }
    if(a == false && !this.state.showUserEntry) {
      this.setState({roundResult: t(this.props.translations, "quiz_correct", this.props.locale) + t(this.props.translations, "quiz_other", this.props.locale), status: "answer", score: this.state.score+1})
    }
    if(a == true && !this.state.showUserEntry) {
      this.setState({roundResult: t(this.props.translations, "quiz_wrong", this.props.locale) + t(this.props.translations, "quiz_other", this.props.locale), status: "answer"})
    }
    if(a == false && this.state.showUserEntry) {
      this.setState({roundResult: t(this.props.translations, "quiz_wrong", this.props.locale) + t(this.props.translations, "quiz_had", this.props.locale), status: "answer"})
    }      
  }

  async componentDidMount() {
    console.log("this.props.quiz_mcmmId", this.props.quiz_mcmmId);
    
    axios.get(apiUrl + "/entry_by_user_quiz/" + this.props.quiz_mcmmId + "/")
    .then((response)=> {
      console.log(response)
      this.setState({
        user_entries: response.data.user_entries,
        others_entries: response.data.others_entries,
      })
      if(response.data.others_entries.length) {
        this.setupNextRound()
      }
    })
    .catch((e)=> {
      console.log(e);
    });
  }

  render() {   
    const entry = this.state.currentEntry
    return (
      <Container>
        {(this.state.status == "ready" || this.state.status == "answer") && <>
          <p>{t(this.props.translations, "quiz_question", this.props.locale)} {this.state.questionCounter}/{this.state.maxQuestions}</p>
          <QuizQuestion>{t(this.props.translations, "quiz_1", this.props.locale)} {entry.year}{t(this.props.translations, "quiz_2", this.props.locale)} {capitalize(entry.city)} {t(this.props.translations, "quiz_3", this.props.locale)} {capitalize(entry.musician)}.</QuizQuestion>
        </>}
        {this.state.status == "ready" && <>
          <p>{t(this.props.translations, "quiz_prompt", this.props.locale)}</p>
          <QuizButton color="#00B1EB" onClick={()=>{this.answer(true)}}>{t(this.props.translations, "quiz_yes", this.props.locale)}</QuizButton>
          <QuizButton color="#E30413" onClick={()=>{this.answer(false)}}>{t(this.props.translations, "quiz_no", this.props.locale)}</QuizButton>
        </>}
        {this.state.status == "answer" && <>
          <QuizAnswer>{this.state.roundResult}</QuizAnswer>
          <p>{t(this.props.translations, "quiz_score", this.props.locale)} {this.state.score}</p>
          <QuizButton color="#FFED00" onClick={()=>{this.setupNextRound()}}>{t(this.props.translations, "quiz_next", this.props.locale)}</QuizButton>
        </>}
        {this.state.status == "end" && <>
          <QuizAnswer>{t(this.props.translations, "quiz_complete", this.props.locale)}</QuizAnswer>
          <QuizAnswer>{t(this.props.translations, "quiz_score", this.props.locale)} {this.state.score} {t(this.props.translations, "quiz_out_of", this.props.locale)} {this.state.maxQuestions}</QuizAnswer>
          <p>{t(this.props.translations, "quiz_prompt_again", this.props.locale)}</p>
          <QuizButton color="#FFED00" onClick={this.props.loadScanner}>{t(this.props.translations, "scanner", this.props.locale)}</QuizButton>
        </>}
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 1.25rem;
  font-family: NeutraTextDemi;
`

const QuizButton = styled.div`
  padding: 1em;
  margin: 10px;
  border: solid 2px ${ props => props.color };
  text-align: center
  :hover {
    cursor: pointer;
  }
`

const QuizAnswer = styled.p`
  font-family: NeutraTextDemi;
  margin-bottom: 1em;
`

const QuizQuestion = styled.div`
  padding: 1em;
  margin: 10px;
`



export default QuizPage;

